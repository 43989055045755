
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/forge/deployments/kinoheld/dev/2024-09-17_18-01-17_c012ee2/apps/kinoheld/app.config.ts"
import cfg1 from "/home/forge/deployments/kinoheld/dev/2024-09-17_18-01-17_c012ee2/layers/base/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, inlineConfig)
